import React from "react";
import Image from "gatsby-image";
import "../../components/style.scss";


let initialState = {
};



let defaultProps = {
    prefix: 0,
    image: "",
    imageClasses: "",
    title: "",
    text: [""],
    titleClasses: "",
    textClasses: "",
    reverseOrder: false
}

type State = Readonly<typeof initialState>
type Props = {
    prefix: string;
    image?: any;
    imageClasses?: string;
    titleClasses?: string;
    title?: string;
    text?: string[];
    textClasses?: string;
    reverseOrder?: boolean;
}


class ImageCard extends React.Component<Props, State> {
    state = initialState;
    props: Props;

    constructor(props) {
        super(props);
        console.log(props);
        this.props = props;
    }

    render() {
        let parent = `
            flex justify-center flex-col w-64 my-6 mx-0 p-0
            md:flex-row md:w-128 lg:w-192
        `;
        let textContainer = `
            text-left font-condensed
            flex flex-col w-64 items-end pb-2 lg:w-96
            md:justify-center 
            ${this.props.reverseOrder ? 'md:pr-4 pb-0' : 'md:pl-4 pb-0'}
        `;
        let title = ` w-full text-2xl font-bold uppercase tracking-wide leading-tight ${this.props.titleClasses}`;
        let subtitle = `w-full text-lg leading-tight ${this.props.textClasses}`;
        let image = `text-center flex shadow items-center justify-center rounded-lg h-48`;
        let imgStyle = { zIndex: "-1" };
        return (
            <div className={parent}>
                 <div className={textContainer}>
                <div className={title}>
                    {this.props.title}
                </div>
                {this.props.text.map((text: string, i: number) => (
                    <React.Fragment key={`${this.props.prefix} ${i.toString()}`}>
                        <div className={subtitle}>{this.props.text[i]}</div>
                    </React.Fragment>
                ))
                }
            </div>
            <Image fixed={this.props.image}
                imgStyle={imgStyle}
                className={image}>
            </Image>
            </div>
        );
    }
}

export default ImageCard;
