import React from "react";
import { graphql, StaticQuery } from "gatsby";
import "./style.scss";
import AudioCard from '../widgets/player-card/audio-card';
import ImageCard from '../widgets/image-card/image-card';
import InfoCard from '../widgets/info-card/info-card';
import IImageData from '../utils/image-types';
import { any } from "prop-types";

let initialState = {
  isPlayingA: false,
  isPlayingB: false,
  toPlay: 'Reflection'
};

interface IProps {
  data: {
    happinessImg: IImageData,
    desireImg: IImageData,
    dutyImg: IImageData,
    harmonyImg: IImageData,
    truthImg: IImageData,
    selfImg: IImageData,
    silouetteAndGalaxy: IImageData,
    fallPath: IImageData,
    monkAndFoliage: IImageData
  }
}

type State = Readonly<typeof initialState>

class Midsection extends React.Component<IProps, State> {
  readonly state: State = initialState
  props: IProps;
  selectedClass: string;
  normalClass: string;

  constructor(data) {
    super(data);
    this.props = data;
  }

  render() {
    let section = `w-full flex flex-wrap flex-col z-0 flex items-center`;
    let columnStyle = `
      flex flex-col w-full items-center justify-center
      lg:flex-row lg:w-192 lg:flex-wrap
    `
    let rowStyle = "flex flex-col w-full items-center justify-center";
    let bottomBorder = `border-b-2 border-gray-600`;
    return (
      <section className={section}>
        <div className={`${columnStyle} ${bottomBorder}`}>
          <AudioCard
            title="Reflection"
            source="300"
            description="Happiness 1"
          ></AudioCard>
          <AudioCard
            title="Meditation"
            source="sample_meditation"
            description="Meditative Space"
          ></AudioCard>
        </div>
        <div className={`${columnStyle} ${bottomBorder}`}>
          <div className={`${columnStyle}`}>

          <InfoCard
            title="Awaken to the Possibile"
            text={["Use reflection to find meaning and discover self-knowledge. These are the conduits for an abiding happiness."]}
          />
            
            <ImageCard
              prefix="1"
              image={this.props.data.happinessImg.childImageSharp.fixed}
              text={["Reawaken inner peace and presence"]}
              title="HAPPINESS"/>

            <ImageCard
              image={this.props.data.desireImg.childImageSharp.fixed}
              text={["Reorient your attitude to the material world"]}
              title="DESIRE" prefix="2"/>
            
            <ImageCard
              image={this.props.data.dutyImg.childImageSharp.fixed}
              text={["Get into the zone where work becomes rewarding"]}
              title="DUTY" prefix="3"/>

            <ImageCard
              image={this.props.data.harmonyImg.childImageSharp.fixed}
              title="HARMONY" 
              text={["Bring authenticity and depth to your relationships"]} prefix="4"
            />

            <ImageCard
              image={this.props.data.truthImg.childImageSharp.fixed}
              text={["Cultivate an insightful and clear-headed perspective"]}
              title="TRUTH" prefix="5"
            />

            <ImageCard
              text={["Transform your self-image into a source of strength"]}
              title="SELF" prefix="6"
              image={this.props.data.selfImg.childImageSharp.fixed}
            />
          </div>
        </div>
        <div className={`${columnStyle} ${bottomBorder}`}>
          <ImageCard
            image={this.props.data.silouetteAndGalaxy.childImageSharp.fixed}
            reverseOrder={false}
            titleClasses="simplifica text-3xl"
            title="INSPIRE YOURSELF"
            text={[""]}
            prefix="7"
          />
          <ImageCard
            image={this.props.data.fallPath.childImageSharp.fixed}
            title="I am, therefore"
            titleClasses="simplifica text-3xl"
            text={["...I think", "...I act", "...I experience", "...I choose", "I meditate and reflect"]}
            prefix="8"
          />
          <ImageCard
            image={this.props.data.monkAndFoliage.childImageSharp.fixed}
            title="Cultivate Conscious Choice"
            titleClasses="simplifica text-3xl"
            text={["Applied to matter, it is the basis of the scientific method.", "Applied to human experience, it is the means of self-knowledge"]}
            textClasses="simplifica"
            prefix="9"
          />
        </div>
        {/**
        <div className={`${columnStyle} mt-5 py-5}`}>
          <InfoCard 
              title="Benefits of Meditation"
              text={["Lowers Stress", "Improves Concentration", "Peaceful Sleep"]}
              cardClasses="bg-gray-200 card-height m-4"
              titleClasses="simplifica uppercase tracking-widest" 
              textClasses="" />
          <InfoCard 
              title="Examples of Reflection"
              text={["What does it mean to live a meaningful life?", "What matters most to us?", "How can we be more happy and content?", "How to conduct ourselves and organize our time?"]}
              cardClasses="bg-gray-200 card-height m-4"
              titleClasses="simplifica uppercase tracking-widest" 
              textClasses="" />
        </div>
         */}

      </section>
    );
  }
}


export default () => (
  <StaticQuery
    query={graphql`
      query MidsectionQuery {
        site {
          siteMetadata {
            title
            author
            imageUrl
            description
            keywords
          }
        }
        monkAndFoliage: file(relativePath: {eq: "amanda-flavell-9XSLoMlVhYU-unsplash.jpg" }) {
          childImageSharp { 
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        fallPath: file(relativePath: {eq: "sebastian-unrau-CoD2Q92UaEg-unsplash.jpg" }) {
          childImageSharp { 
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        silouetteAndGalaxy: file(relativePath: {eq: "greg-rakozy-oMpAz-DN-9I-unsplash.jpg" }) {
          childImageSharp { fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        happinessImg: file(relativePath: {eq: "happiness_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        dutyImg: file(relativePath: {eq: "duty_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192)  {
              ...GatsbyImageSharpFixed
            }
          }
        }
        harmonyImg: file(relativePath: {eq: "harmony_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        desireImg: file(relativePath: {eq: "desire_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        selfImg: file(relativePath: {eq: "self_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        truthImg: file(relativePath: {eq: "truth_rep.png" }) {
          childImageSharp {
            fixed(quality: 30, width: 256, height: 192) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        happinessAudio: allFile(filter: { extension: { eq: "mp3" } }) {
          edges {
            node {
              publicURL
            }
          }
        }
      }`
    }
    render={data => (
      <Midsection data={data} />
    )}
  />
)
