import React from "react";
import "./style.scss";
import { StaticQuery, graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import Navbar from "./navbar";

let initialState = {
};

type State = Readonly<typeof initialState>

interface IProps {
  // `data` comes from the query
  data: {
    backgroundImg: {
      childImageSharp: { fluid: any; }
    }
    appleStore: {
      childImageSharp: { fluid: any; }
    },
    androidStore: {
      childImageSharp: { fluid: any; }
    }
  }
}

class Header extends React.Component<IProps, State> {
  state = initialState;
  constructor(props) {
    super(props);
  }
  render() {
    // Set ImageData.
    let imgStyle = {
      objectFit: "contain",
      objectPosition: "50% 50%",
    };
    let backgroundStyle = {
      backgroundPosition: "center",
      backgroundRepeat: "none",
      backgroundSize: "contain",
      objectPosition: "50% 50%",
    }
    let imgClasses = `h-10 w-40 z-0 m-2`;
    return (
      <section className="m-0 p-0 pt-10">
        {/* 
          // @ts-ignore */}
        <BackgroundImage
          fluid={this.props.data.backgroundImg.childImageSharp.fluid}
          style={backgroundStyle}
          className="m-0 p-0 object-contain"
        >
          
          <article className="hero flex py-20">
            <div className="flex flex-col justify-center flex-grow">
              <h2 className="text-white text-6xl self-center simplifica tracking-widest font-thin">SUM, ERGO</h2>
              <h2 className="text-white text-3xl self-center tracking-wide font-thin">Meaningful Meditation</h2>
              <div className="flex flex-wrap flex-row justify-center m-2 my-8">

              <div className="
                flex-col md:flex-row
                w-auto p-2">
                  <a href="https://apps.apple.com/us/app/sum-ergo/id1257476602">
                    <Img imgStyle={imgStyle} className={imgClasses} 
                    fluid={this.props.data.appleStore.childImageSharp.fluid} />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.sumergoapp.sumergo">
                    <Img imgStyle={imgStyle} className={imgClasses} 
                    fluid={this.props.data.androidStore.childImageSharp.fluid} />
                  </a>
                  </div>
              </div>
            </div>
          </article>
        </BackgroundImage>
      </section >
    );
  }
}

export default (parentData) => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImg: file(relativePath: { eq: "rising-sun.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        appleStore: file(relativePath: {eq: "apple-store-badge.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        androidStore: file(relativePath: {eq: "google-play-badge.png"}) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Header data={data} parentData={parentData} />
    )}
  />
);
