import React from "react";
import "./loader.scss";
import { string } from "prop-types";

const inputDefaultStyle = "w-64 h-8 my-2 px-2 rounded-lg text-gray-800 outline-none";
const invalidStyle = "border-2 border-red-500";
const validatedStyle = "border-2 border-teal-500";
const submitInvalid = "bg-gray-200 text-gray-800 my-2 px-2 rounded-lg";
const submitValid = "bg-green-200 text-gray-800 my-2 px-2 rounded-lg border-green-500";

let initialState = {
  visible: false,
  email: "",
  name: "",
  success: false,
  failure: false,
  errorMsg: "",
  busy: false
};

let defaultProps = {
  show: false,
  url: "https://aq12uvr183.execute-api.us-east-1.amazonaws.com/dev/mailManage"
}

type Props = {
  show?: boolean,
  url?: string
};

type State = {
  visible: boolean,
  email?: string,
  name?: string,
  success?: boolean,
  failure?: boolean,
  errorMsg?: string,
  busy?: boolean
}

class EmailSignup extends React.Component<Props, State> {
  state = initialState;

  constructor(props) {
    super(props);
    if (this.props.show) {
      this.setState({ visible: true })
    }
  }

  validateEmail = () => {
    if (this.state.email.indexOf('@') > 1) {
      return true;
    } else { return false; }
  }
  validateName = () => {
    if (this.state.name.length > 2) {
      return true;
    } else { return false; }
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  }
  handleNameChange = event => {
    this.setState({ name: event.target.value });
  }

  handleSubmit = event => {
    this.setState({ failure: false, success: false })
    event.preventDefault();
    this.setState({ busy: true });
    fetch("https://aq12uvr183.execute-api.us-east-1.amazonaws.com/dev/mailManage", {
      method: 'POST',
      body: JSON.stringify({
        names: [this.state.name],
        emails: [this.state.email],
        action: "add"
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status === 200) {
          this.setState({ success: true, busy: false });
        } else {
          this.setState({ failure: true, errorMsg: response.body['errorMsg'], busy: false })
        }
      })
      .catch((error) => {
        this.setState({ failure: true, busy: false });
      });
  }
  componentWillReceiveProps(props) {
    this.setState({ visible: props.show })
  }
  render() {

    return (
      <div className={`${this.state.visible ? '' : 'hidden'} fixed pin z-50 overflow-auto shade-bg flex h-full w-full w-max-screen-lg`}>
        <div className="flex items-center justify-center w-full">
          <div className="flex flex-col flex-shrink  h-42 bg-white p-4">
            <form onSubmit={this.handleSubmit}>
              <div className="w-full flex flex-row items-center">
                <div className={`flex-grow`}>
                  <div className="flex justify-center">
                    <div className={`${this.state.failure ? '' : 'hidden'} text-red-500`}>Failure</div>
                    <div className={`${this.state.success ? '' : 'hidden'} text-green-500`}>Success</div>
                  </div>
                </div>
                <div className="justify-end flex-shrink" onClick={() => { this.setState({ visible: false }) }}>
                  <svg className="svg-icon" viewBox="0 0 20 20">
                    <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
                  </svg>
                </div>
              </div>
              <div className="flex-grow flex flex-row items-center justify-center">
                <div className="mr-4">Name </div>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  id="name"
                  className={`${inputDefaultStyle} ${this.validateName() ? validatedStyle : invalidStyle}`} onChange={this.handleNameChange}
                />
              </div>
              <div className="flex-grow flex flex-row items-center justify-center">
                <div className="mr-4">Email </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  id="email"
                  className={`${inputDefaultStyle} ${this.validateEmail() ? validatedStyle : invalidStyle}`} onChange={this.handleEmailChange}
                />
              </div>
              <button type="submit" className={`${inputDefaultStyle} ${this.validateEmail() ? submitValid : submitInvalid} w-full`} onChange={this.handleSubmit}>
                <div className="flex w-full h-full">
                  <div className={`${this.state.busy ? 'hidden' : ''} flex flex-grow h-full justify-center items-center`}>Submit</div>
                  <div className={`${this.state.busy ? '' : 'hidden'} flex w-full h-full justify-center items-center`}>
                    <svg className={`svg-spinner linear-infinite`} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                      <circle className="circle" cx="50" cy="50" r="45" />
                    </svg>
                  </div>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>);
  }
}

export default EmailSignup;