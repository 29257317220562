import React from "react";
import "../../components/style.scss";

let initialState = {
};

let defaultProps = {
    title: "",
    text: [""],
    cardClasses: "",
    titleClasses: "",
    textClasses: ""
}

type State = Readonly<typeof initialState>
type Props = {
  title?: string;
  text?: string[];
  cardClasses?: string;
  titleClasses?: string;
  textClasses?: string;
};

class InfoCard extends React.Component<Props, State> {
  state = initialState;

  constructor(props) {
    super(props);
  }

  render() {
    let prefix = Math.round(Math.random()*1000).toString();
        
    let parent = `
    flex justify-center flex-col w-64 my-6
    md:w-128 
`;
    let title = `
      w-full 
      simplifica text-3xl font-bold uppercase tracking-widest leading-tight
      ${this.props.titleClasses}
    `;
    let subtitle = `w-full font-condensed text-xl leading-tight text-gray-800 ${this.props.textClasses}`;

    return (
    <div className={parent}>
      <div className={title}>{this.props.title}</div>
      <div>
      {this.props.text.map((text: string, i: number) => <div className={subtitle} key={`${prefix}${i.toString()}`}>{text}</div>)}
      </div>
    </div>);
  }
}

export default InfoCard;