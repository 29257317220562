import React from "react";

import "../components/style.scss";
import Helmet from "../components/helmet";
import Header from "../components/header";
import Navbar from "../components/navbar";
import Midsection from "../components/midsection";
import Footer from "../components/footer";
import EmailSignup from "../widgets/email-management/mailing-list";

let initialState = {
  mailingActive: false
};

let defaultProps = {
  callbackFromParent: (msg: string, value: any) => { }
}

type State = Readonly<typeof initialState>
type Props = Readonly<typeof defaultProps>

class Layout extends React.Component<Props, State> {
  state = initialState;
  handleClick() {
    let a = function () {
      console.log('Click happened');
    }
    a();
  }
  parentCallback = (msg: string, value: any) => {
    if (msg === "mailingActive") {
      if (value === true) {
        this.setState({mailingActive: true});
      }
    }
  }

  render() {
    return (
      <div className="w-full flex justify-center">
        <div className="max-w-screen-lg w-full">
          <EmailSignup show={this.state.mailingActive} />
          <Helmet />
          <Navbar callback={this.parentCallback} />
          <Header />
          <Midsection/>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Layout;
