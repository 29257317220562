import React from "react";
import { graphql, StaticQuery } from "gatsby";
import AudioPlayer from "./audio-root";
import { FaVolumeUp } from "react-icons/fa";
import "../../components/style.scss";

let initialState = {
  isPlaying: false,
  toPlay: "Reflection",
  timeString: "00:00",
  progress: 0
};

type State = Readonly<typeof initialState>

interface INode {
  node: {
    name: string,
    publicURL: string
  }
}

interface IProps {
  parentData: {
    title: string,
    source: string,
    description: string
  }
  data: {
    allMp3: {
      edges: INode[]
    }
  }
}


class AudioCard extends React.Component<IProps, State> {
  state = initialState;
  title = "";
  source = "";
  description = "";
  publicUrl = "";

  constructor(props) {
    super(props);
    this.title = this.props.parentData.title;
    this.source = this.props.parentData.source;
    this.description = this.props.parentData.description;
    for (let i = 0; i < this.props.data.allMp3.edges.length; i++) {
      let node = this.props.data.allMp3.edges[i].node;
      if (node.name === this.source) {
        this.publicUrl = node.publicURL;
        break;
      }
    }
  }

  render() {
    let parent = `flex flex-col py-4 px-2 w-full max-w-screen-sm`;
    let heading = `flex flex-wrap justify-left items-center`;
    let volume = `pl-2 mr-6`;
    let category = `text-md text-gray-800 tracking-wide uppercase font-condensed`;
    let title = `px-4 text-md text-gray-800 tracking-wide font-condensed font-bold`
    let playerBox = `flex border-2 bg-white rounded my-1 items-center`;
    return (
      <div className={parent}>
        <div className={heading}>
          <div className={volume} style={{ color: '#2D3748' }}>
            <FaVolumeUp size={18}></FaVolumeUp>
          </div>
          <div className={category}>{this.title}</div>
          <div className={title}>{this.description}</div>
        </div>
        <div className={playerBox}>
          <div className="flex p-6 flex-grow">
            <AudioPlayer source={this.publicUrl} progressCallback={this.updateAudioStatus}></AudioPlayer>
            <div className="flex flex-col flex-grow ">
              <div className="ml-6 tracking-wider">
                {this.state.timeString}
              </div>
              <div className="bg-gray-200 mx-6 flex-grow-1">
                <div className="bg-teal-400 h-3" style={{ "width": `${String(100 * this.state.progress)}%` }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  updateAudioStatus = (time: number, duration: number) => {
    this.setState({ progress: time / duration, timeString: "-" + this.secondsToTimeString(duration - time) });
  }
  secondsToTimeString(seconds: number): string {
    let minutes = Math.floor(seconds / 60);
    let secondsRemainder = Math.round(seconds - minutes * 60);
    let timeString = String(minutes) + ":";
    if (secondsRemainder < 10) {
      timeString = timeString + "0" + String(secondsRemainder);
    } else { timeString = timeString + "" + String(secondsRemainder); }
    return timeString;
  }
}


export default (parentData) => (
  <StaticQuery
    query={graphql`
      query AudioQuery {
        allMp3: allFile(filter: { extension: { eq: "mp3" } }) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }`
    }
    render={data => (
      <AudioCard data={data} parentData={parentData} />
    )}
  />
)
