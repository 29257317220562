import React from "react";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { convertSecondsToString } from "../../utils/convert-time-string";

let initialState = {
  audioRef: null,
  isPlaying: false,
  currentTime: 0
};

type State = Readonly<typeof initialState>

interface IAudioPlayerProps {
  source: string, 
  progressCallback: any,
}

class AudioPlayer extends React.Component<IAudioPlayerProps, State> {
  readonly state: State = initialState
  audioSource: any;
  progressCallback: any;

  constructor(props: IAudioPlayerProps) {
    super(props);
    this.audioSource = props.source;
    this.progressCallback = props.progressCallback;
  }

  setAudioRef = (element: any) => {
    if (element !== null && element !== undefined) {
      this.setState(() => { return {audioRef: element} });
      element.addEventListener('loadedmetadata', (data) => {
        this.progressCallback(data.srcElement.currentTime, data.srcElement.duration)
    },false);
    element.addEventListener("timeupdate", (data) => {
        this.progressCallback(data.srcElement.currentTime, data.srcElement.duration)
      });
    }
  };
  
  pause = () => {
    if (this.state.audioRef !== null) {
      (this.state.audioRef as any).pause();
      this.setState({ isPlaying: false });
    }
  };

  play = () => {
    if (this.state.audioRef !== null) {
      if (this.state.audioRef !== undefined) {
        (this.state.audioRef as any).play();
        this.setState({ isPlaying: true });
      }
    }
  };
  
  render() {
    return (
      <div>
        <audio 
          ref={this.setAudioRef}
          src={this.audioSource}
          loop={false}
          onPlay={this.onPlay}
        />
        <div>
          <span onClick={this.pause} className="text-red-800">
            {this.state.isPlaying ? <FaPauseCircle size={52}/> : null}
          </span>
          <span onClick={this.play} className="text-teal-500">
            {this.state.isPlaying ? null : <FaPlayCircle size={52}/>}
          </span>
        </div>
      </div>
    );
  }
  componentDidMount() {
  }
  componentWillUnMount() {
    this.state.audioRef.removeEventListener('loadedmetadata');
    this.state.audioRef.removeEventListener('timeupdate');
  }
  onAbort: () => {}
  onCanPlay: () => {}
  onCanPlayThrough: () => {}
  onEnded: () => {}
  onError: () => {}
  onListen: () => {}
  onPause: () => {}
  onPlay: () => {}
  onSeeked: () => {}
  onVolumeChanged: () => {}
  onLoadedMetadata: () => {}
}

export default AudioPlayer;
